import z from 'zod';

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Env {
  export const schema = z.enum(['development', 'production']);

  export type Type = z.infer<typeof schema>;
}

type Config = {
  env: Env.Type;
  apiBaseUrl: string;
};

export const configByEnv: Record<Env.Type, Config> = {
  development: {
    env: 'development',
    apiBaseUrl: 'http://localhost:3000',
  },
  production: {
    env: 'production',
    apiBaseUrl: 'https://api.alkobi.space',
  },
};

export const config =
  configByEnv[Env.schema.parse(process.env['NEXT_PUBLIC_ORIGIN_ENV'])];
