'use client';
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import styles from './page.module.css';
import { config } from './config';

const RootPage = () => {
  const [helmetDone, setHelmetDone] = useState<boolean>(false);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //
  //   script.src = 'https://api.alkobi.space/test/files/download/direct';
  //   script.async = true;
  //
  //   document.body.appendChild(script);
  //
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //
  //   script.src = 'http://localhost:3000/test/files/download/direct/2';
  //   script.async = true;
  //
  //   document.body.appendChild(script);
  //
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://oyfzhldkuzcqtvyuqowl.supabase.co/storage/v1/object/public/origin-public/hello-world-3.js?t=2024-08-30T15%3A43%3A35.212Z';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //
  //   script.src = './hello-world-4.js';
  //   script.async = true;
  //
  //   document.body.appendChild(script);
  //
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <main className={styles.main}>
      <p>Main page :)</p>
      <p>{`config: ${JSON.stringify(config)}`}</p>
    </main>
  );
};

export default RootPage;
